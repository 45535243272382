const loadGoogleMapsApi = require('load-google-maps-api');

class GoogleMap {
  constructor(args = {}) {
    this.mapElement = document.getElementById(args.mapId || 'google-map-default')
    this.mapPlaceholder = document.getElementById(args.placeholderId || 'location-placeholder')
    this.coords = this.coordsFromMapElement(args.coords)
    this.mapOptions = this.defaultOptions()
    this.markerOptions = this.markerOptions()
    this.setupDone = false
    if (args.show) { this.showMap() }
  }

  coordsFromMapElement(coords) {
    return coords || { lat: parseFloat(this.mapElement.dataset.lat), lng: parseFloat(this.mapElement.dataset.lng) }
  }

  defaultOptions(options) {
    const defaults = {
      center: this.coords,
      zoom: 12,
      maxZoom: 20,
      draggable: true,
      mapTypeId: 'terrain'
    }

    return { ...defaults, ...options }
  }

  loadGoogleMapsApi() {
    return loadGoogleMapsApi({ key: 'AIzaSyCdy5VEZ5doYy9msGrc1CYKgD-wip3G-zs' })
  }

  markerOptions(options) {
    const coords = this.coords
    const defaults = {
        position: coords,
        title: 'campground marker'
    }

    return { ...defaults, ...options }
  }

  setup() {
    this.loadGoogleMapsApi().then((googleMaps) => {
      this.setupPlugin(googleMaps, this.mapElement)
    })
    this.setupDone = true
  }
  setupPlugin(googleMaps, mapElement) {
    const map = new googleMaps.Map(mapElement, this.mapOptions)
    var marker = new googleMaps.Marker(this.markerOptions);
    marker.setMap(map);

    return map
  }

  showMap() {
    if (!this.setupDone) { this.setup() }
    this.mapElement.classList.remove('d-none')
    this.mapPlaceholder.classList.add('d-none')
  }
}

document.addEventListener("turbolinks:load", function() {
  if (document.getElementById('google-map-default')) {
    let map = new GoogleMap()

    if (window.location.hash === '#location') { map.showMap() }

    for (let element of document.getElementsByClassName('show-location')) {
      element.addEventListener('click', () => { map.showMap() });
    }
  }
})
