var tooltip_template = function ($el) {
  var customClass = $el.data('custom-class') || ''
  return '<div class="tooltip' + customClass + '" role="tooltip">' +
           '<div class="tooltip-arrow"></div>' +
           '<div class="tooltip-inner"></div>' +
         '</div>'
}

document.addEventListener('turbolinks:load', function () {
  $('[data-toggle="tooltip"]').each(function (_, el) {
    var $el = $(el)
    $el.tooltip({ html: true, template: tooltip_template($el) })
  })
})
