// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('turbolinks').start()
require('channels')

// TODO: use the FA node/yarn package instead?
import 'gems/fontawesome5.js'

// Import dependencies
import 'bootstrap'
import 'lightpick'
import 'moment'
import 'smooth-scroll'

// Import JS
import 'shared/bootstrap'
import 'shared/daterange'
import 'shared/back_to_top'
import 'shared/google_analytics'
import 'shared/google_map.js.erb'
import 'shared/lightbox'
import 'shared/smooth_scroll'
import 'shared/sticky_switcher'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// allow accessing `$` & jQuery in the browser console
$ = require('jquery'); window.jQuery = $; window.$ = $;
