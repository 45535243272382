const Lightpick = require('lightpick')

class Datepicker {
  constructor(args = {}) {
    this.date_range = document.getElementById(args.date_range || 'date_range')
    this.check_in_field = document.getElementById(args.check_in ||'check_in')
    this.check_out_field = document.getElementById(args.check_out ||'check_out')
    this.close_season_dates = document.getElementById('search-date').dataset.closeSeasonDates
    this.options = this.useOptionsFor(args)
    this.setup()
  }

  closeSeasonDates() {
    if (this.close_season_dates != undefined) {
      return JSON.parse(this.close_season_dates)
    }
  }

  splitRangeToDates() {
    [start, end] = this.detectDates(this.light.getStartDate(), this.light.getEndDate(), this.date_range.value);

    this.check_in_field.value = start;
    this.check_out_field.value = end;
  }

  detectDates(start, end, value) {
    if(start !== null && end !== null) {
      return ([start.format('MM/DD/YYYY'), end.format('MM/DD/YYYY')]);
    }
    return value.split('-')
  }

  defaultOnClose() {
    if (this.light.getStartDate() === null || this.light.getEndDate() === null) {
      this.check_in_field.value = ''
      this.check_out_field.value = ''
      this.light.setDateRange(null, null)
    }
  }

  useOptionsFor(args = {}) {
    const defaults = {
      disableDates: this.closeSeasonDates(),
      field: this.date_range,
      firstDay: 7,
      format: 'MM/DD/YYYY',
      minDate: Date.now(),
      minDays: 2,
      numberOfMonths: 2,
      singleDate: false,
      onClose: () => this.defaultOnClose()
    }
           
    return { ...defaults, ...args };
  }

  setup(options = null) {
    options || (options = this.options)
    this.light = new Lightpick(options)
  }
}

function submitEachDatepicker(datepickers, datepicker) {
  datepickers.forEach(function (picker) {
    formId = picker.date_range.getAttribute('data-target');
    form = document.getElementById(formId)
    if (form) {
      form.onsubmit = () => datepicker.splitRangeToDates()
    }
  })
}

document.addEventListener('turbolinks:load', function() {
  const datepickers = [document.getElementsByClassName('datepicker')];
  if (datepickers.length) {
    const datepicker = new Datepicker();
    submitEachDatepicker(datepickers, datepicker)
  }
})
