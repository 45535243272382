import '@fancyapps/fancybox'
import '@fancyapps/fancybox/dist/jquery.fancybox.css'

document.addEventListener("turbolinks:render", function() {
  const elements = document.getElementsByClassName('fancybox')
  const hash = window.location.hash.match(/\d+/g)
  if (elements && hash) {
    $.fancybox.open(elements, {}, (hash[0]-1))
  }
})
