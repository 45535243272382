class BackToTop {
  constructor() {
    this.backToTop = document.getElementById('back-to-top');
    this.documentTop = this.backToTop.href;
  }

  scrollFunction() {
    const scrollBody = document.body.scrollTop;
    const scrollDocument = document.documentElement.scrollTop;

    if (scrollDocument > 100 || scrollBody > 100) {
      this.backToTop.classList.remove('d-none');
    } else {
      this.backToTop.classList.add('d-none');
    }
  }

  topFunction() {
    scrollBody = documentTop;
    scrollDocument = documentTop;
  }
}

document.addEventListener('turbolinks:load', function() {
  const toTopBtn = document.getElementById('back-to-top')
  if (toTopBtn) {
    const backToTop = new BackToTop();
    document.onscroll = function() { backToTop.scrollFunction() };
    backToTop.onclick = function() {  backToTop.topFunction() };
  }
})
